import React, { Fragment } from 'react'
import { useListTableRow } from '@newageerp/v3.app.list.list-table-row';
import { Audio } from '@newageerp/v3.data.file';
import { StatusWidget } from '@newageerp/v3.widgets.status-widget';
import { Date, DateTime } from '@newageerp/v3.data.date-time'
import { PropertyType, useDataCache } from '@newageerp/v3.app.data-cache-provider'
import { String } from '@newageerp/v3.data.text';
import { File } from '@newageerp/v3.data.file';
import { FileMultiple } from '@newageerp/v3.data.file';
import { Float } from '@newageerp/v3.data.number';
import { Int } from '@newageerp/v3.data.number'
import { Data } from '@newageerp/crm-ui'
import { BoolEditableColumn, FloatEditableColumn, LargeTextEditableColumn } from '@newageerp/v3.app.fields.editable-columns'
import { RsButton } from '@newageerp/v3.bundles.buttons-bundle'
import { Td } from '@newageerp/v3.bundles.layout-bundle'
import classNames from 'classnames';

enum EnumBorderColors {
    red = 'shadow-[inset_1px_0px_0px_0px_rgba(248,113,113,1)]',
    purple = 'shadow-[inset_1px_0px_0px_0px_rgba(192,132,252,1)]',
    blue = 'shadow-[inset_1px_0px_0px_0px_rgba(96,165,250,1)]',
    sky = 'shadow-[inset_1px_0px_0px_0px_rgba(56,189,248,1)]',
    lime = 'shadow-[inset_1px_0px_0px_0px_rgba(163,230,53,1)]',
    amber = 'shadow-[inset_1px_0px_0px_0px_rgba(251,191,36,1)]',
    orange = 'shadow-[inset_1px_0px_0px_0px_rgba(251,146,60,1)]',
    pink = 'shadow-[inset_1px_0px_0px_0px_rgba(244,114,182,1)]',
    teal = 'shadow-[inset_1px_0px_0px_0px_rgba(45,212,191,1)]',
    slate = 'shadow-[inset_1px_0px_0px_0px_rgba(148,163,184,1)]',
    bronze = 'shadow-[inset_1px_0px_0px_0px_rgba(161,98,7,1)]',
    black = 'shadow-[inset_1px_0px_0px_0px_rgba(30,41,59,1)]',
}

type PropsOptions = {
    multiRow?: {
        initRows?: number;
        initShowAll?: boolean;
        expandIn?: 'inline' | 'popup'
    }
}

type PropsLink = {
    defaultClick: 'none' | 'main' | 'modal'
}

type Props = {
    path: string,
    editable?: boolean,
    options?: PropsOptions
    link?: PropsLink,
    style?: {
        alignment?: string,
        className?: string | string[]
    },
}

export const UniRoColumn = (props: Props) => {
    const { element } = useListTableRow();
    const { system: dsSystem } = useDataCache();

    if (!element) {
        return <Fragment />;
    }

    const pathArray = props.path.split(".");
    pathArray.shift();

    const getValue = () => {
        // element[props.fieldKey];
        let value: any = undefined;
        try {
            value = pathArray.reduce((previous, current) => previous[current], element);
        } catch (e) {

        }
        return value;
    }

    const prop = dsSystem.helpers.property.fromPath(props.path);

    const cellValue = <CellValue {...props} prop={prop} element={element} getValue={getValue} />

    let extraClassNames : string[] = [];

    // enum class name
    if (!!prop.enums && prop.enums.length > 0) {
        const _enum = prop.enums.find(e => e.value === getValue());
        if (_enum && !!_enum.color) {
            extraClassNames.push(EnumBorderColors[_enum.color])
        }
    }

    return <Td className={
        classNames(
            props.style?.alignment,
            props.style?.className,
            extraClassNames,
        )
    }>{cellValue}</Td>
}

type CellValueProps = {
    element: any
    prop: PropertyType
    getValue: () => any,
} & Omit<Props, 'style'>

const CellValue = (props: CellValueProps) => {
    const pathArray = props.path.split(".");
    pathArray.shift();
    const { element, prop } = props;

    if (props.editable) {
        return <EditableRoColumnValue prop={prop} />
    } else {
        const comp = <UniRoColumnValue
            prop={prop}
            path={props.path}
            editable={props.editable}
            options={props.options}
            getValue={props.getValue}
        />;

        if (props.link) {
            let elementId = element.id;
            if (pathArray.length > 1) {
                const idPathArray = [...pathArray.slice(0, pathArray.length - 1), 'id'];
                let idValue: any = undefined;
                try {
                    idValue = idPathArray.reduce((previous, current) => previous[current], element);
                } catch (e) { }
                if (typeof idValue !== 'undefined') elementId = idValue;
            }
            return <RsButton
                schema={prop.entity}
                id={elementId}
                defaultClick={props.link.defaultClick}
                button={{
                    children: comp,
                    color: 'white',
                    skipPadding: true
                }}

            />
        }
        return comp;

    }
}


type EditableRoColumnValueProps = {
    prop: PropertyType
}

const EditableRoColumnValue = ({ prop }: EditableRoColumnValueProps) => {
    if (prop.type === 'bool') {
        return <BoolEditableColumn fieldKey={prop.key} schema={prop.entity} />
    }
    if (prop.type === 'float') {
        return <FloatEditableColumn fieldKey={prop.key} schema={prop.entity} accuracy={2} />
    }
    if (prop.type === 'float4') {
        return <FloatEditableColumn fieldKey={prop.key} schema={prop.entity} accuracy={4} />
    }
    if (prop.type === 'text') {
        return <LargeTextEditableColumn fieldKey={prop.key} schema={prop.entity} />
    }
    if (prop.type === 'string') {
        return <LargeTextEditableColumn fieldKey={prop.key} schema={prop.entity} />
    }
    return <Fragment />
}

type UniRoColumnValueProps = {
    prop: PropertyType
    path: string,
    editable?: boolean,
    options?: PropsOptions
    getValue: () => any,
}

const UniRoColumnValue = (props: UniRoColumnValueProps) => {
    const { system: dsSystem } = useDataCache();

    const { prop, getValue } = props;

    if (prop.type === 'audio') {
        return <Audio value={getValue()} />
    }
    if (prop.type === 'image') {
        return <img src={getValue()} className={'w-56'} />
    }
    if (prop.type === 'bool') {
        const v = getValue();
        return <StatusWidget color={v ? "teal" : "slate"}>{v ? "Yes" : "No"}</StatusWidget>;
    }
    if (prop.type === 'color') {
        const v = getValue();
        return <StatusWidget color={v}>{v}</StatusWidget>
    }
    if (prop.type === 'date') {
        return <Date value={getValue()} format={process.env.REACT_APP_DATE_FORMAT} />
    }
    if (prop.type === 'datetime') {
        return <DateTime value={getValue()} />
    }
    if (prop.type === 'enum_multi_number') {
        return <div>TODO</div>
    }
    if (prop.type === 'enum_multi_text') {
        return <div>TODO</div>
    }
    if (prop.type === 'enum_number' || prop.type === 'enum_text') {
        const v = getValue();
        const _enum = prop.enums.find(e => e.value === v);
        if (_enum) {
            return <String value={_enum.label} />;
        } else {
            return <String value={v} />;
        }
    }
    if (prop.type === 'file') {
        return <File file={getValue()} short={true} />;
    }
    if (prop.type === 'fileMultiple') {
        const v = getValue();
        const fileV = Array.isArray(v) ? v : [];
        return <FileMultiple files={fileV} short={true} />
    }
    if (prop.type === 'float') {
        return <Float value={getValue()} accuracy={2} />
    }
    if (prop.type === 'float4') {
        return <Float value={getValue()} accuracy={4} />
    }
    if (prop.type === 'html-editor') {
        return <div dangerouslySetInnerHTML={getValue()}></div>
    }
    if (prop.type === 'text') {
        const v = getValue();
        const options = props.options?.multiRow;

        return (
            <div>
                <Data.TextMultiline
                    text={v ? v : ""}

                    initRows={!!options ? options.initRows : 3}
                    initShowAll={options?.initShowAll}
                    expandIn={options?.expandIn}
                />
            </div>
        )
    }
    if (prop.type === 'number') {
        return <Int value={getValue()} />
    }

    if (prop.type === 'status' || prop.type === 'status-short') {
        const isShort = prop.type === 'status-short';
        const statusText = dsSystem.helpers.status.label(prop.entity, prop.key, getValue());
        const statusColor = dsSystem.helpers.status.color(prop.entity, prop.key, getValue());
        return (<StatusWidget
            isCompact={isShort}
            title={statusText}
            className="max-w-[120px] truncate"
            color={statusColor}
        >
            {statusText}
        </StatusWidget>)
    }

    if (prop.type === 'string_array') {
        const v = getValue();
        const multiV = v ? v.join("\n") : "";
        const options = props.options?.multiRow;
        return <div>
            <Data.TextMultiline
                text={multiV}
                initRows={!!options ? options.initRows : 3}
                initShowAll={options?.initShowAll}
                expandIn={options?.expandIn}
            />
        </div>
    }
    if (prop.type === 'string') {
        return <String value={getValue()} />
    }

    return <Fragment />
}
